<template>
  <!--begin::Calendar-->
  <div class="resource-calendar-template" v-if="getPermission('calendar:view')">
    <DetailTemplate custom-second-class="pt-0">
      <template v-slot:body>
        <v-container fluid class="pt-0">
          <v-row class="fill-height">
            <v-col md="12">
              <v-layout class="justify-content-end">
                <v-flex md2>
                  <v-autocomplete
                    :disabled="statusLoading"
                    :loading="statusLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    clearable
                    flat
                    solo
                    dense
                    filled
                    multiple
                    label="Select Status"
                    placeholder="Select Status"
                    color="cyan"
                    item-color="cyan"
                    item-text="text"
                    item-value="status"
                    v-on:change="reloadCalendar()"
                  >
                    <template v-slot:selection="{ item, index }">
                      <span v-if="index === 0">{{ item.text }}</span>
                      <span
                        v-if="index === 1"
                        class="ml-2 grey--text text-caption"
                      >
                        (+{{ status.length - 1 }} more)
                      </span>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-action class="mr-0">
                        <v-chip
                          style="height: 16px; width: 16px; padding: 0"
                          :color="item.color"
                        >
                        </v-chip>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-capitalize font-weight-500 font-size-16"
                          >{{ item.text }}</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action
                        class="align-self-center"
                        v-if="status.includes(item.status)"
                      >
                        <v-icon color="cyan">mdi-check</v-icon>
                      </v-list-item-action>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex md2>
                  <v-autocomplete
                    :disabled="customerLoading"
                    :loading="customerLoading"
                    :items="customerList"
                    v-model="customer"
                    multiple
                    hide-details
                    clearable
                    flat
                    solo
                    dense
                    filled
                    label="Select Customer"
                    placeholder="Select Customer"
                    color="cyan"
                    item-color="cyan"
                    item-text="display_name"
                    item-value="id"
                    v-on:change="reloadCalendar()"
                  >
                    <template v-slot:selection="{ item, index }">
                      <span v-if="index === 0">{{ item.display_name }}</span>
                      <span
                        v-if="index === 1"
                        class="ml-2 grey--text text-caption"
                      >
                        (+{{ customer.length - 1 }} more)
                      </span>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex md2>
                  <v-autocomplete
                    :disabled="engineerLoading"
                    :loading="engineerLoading"
                    :items="engineerList"
                    v-model="engineer"
                    hide-details
                    clearable
                    flat
                    solo
                    dense
                    filled
                    label="Select Engineer"
                    placeholder="Select Engineer"
                    color="cyan"
                    multiple
                    item-color="cyan"
                    item-text="display_name"
                    item-value="id"
                    v-on:change="reloadCalendar()"
                  >
                    <template v-slot:selection="{ item, index }">
                      <span v-if="index === 0">{{ item.display_name }}</span>
                      <span
                        v-if="index === 1"
                        class="ml-2 grey--text text-caption"
                      >
                        (+{{ engineer.length - 1 }} more)
                      </span>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-avatar
                        class="mr-0"
                        height="30"
                        width="30"
                        min-width="30"
                        color="grey lighten-2 grey--text"
                      >
                        <span class="fw-500 mb-1">
                          {{ item.display_name.slice(0, 2).toUpperCase() }}
                        </span>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-capitalize font-weight-500 font-size-16"
                          >{{ item.display_name }}</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action
                        class="align-self-center"
                        v-if="engineer.includes(item.id)"
                      >
                        <v-icon color="cyan">mdi-check</v-icon>
                      </v-list-item-action>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex md2>
                  <Datepicker
                    solo
                    flat
                    :default-date="defaultDate"
                    v-on:update:date-picker="setDefaultDate($event)"
                  ></Datepicker>
                </v-flex>
                <v-flex class="ml-4 d-flex" style="max-width: 210px">
                  <div class="w-100">
                    <v-btn
                      class="custom-bold-button w-100"
                      v-on:click="$router.push(getDefaultRoute('calendar'))"
                      depressed
                    >
                      Calendar
                    </v-btn>
                  </div>
                  <div class="w-100">
                    <v-btn
                      class="custom-bold-button w-100"
                      depressed
                      color="cyan white--text"
                    >
                      Resource
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col md="9">
              <div id="bt-calendar"></div>
            </v-col>
            <v-col md="3">
              <template v-if="visit.length">
                <h3
                  class="custom-header-blue-text font-size-18 m-0 text-uppercase px-2"
                  style="line-height: 52px !important"
                >
                  Un-Assigned Visits
                </h3>
                <div
                  style="max-height: calc(100vh - 200px); overflow-y: auto"
                  id="external-draggable"
                >
                  <div
                    class="fc-event custom-grey-border p-2 mb-2"
                    v-for="(row, index) in visit"
                    :data-event="JSON.stringify(row)"
                    :key="index"
                  >
                    <p class="m-0">
                      <Barcode dense :barcode="row.barcode" small></Barcode>
                    </p>
                    <p
                      class="m-0 font-weight-600 font-size-17 my-1 visit-title"
                    >
                      {{ row.title }}
                    </p>
                    <p class="m-0 font-weight-600 d-flex my-auto">
                      <v-icon
                        class="chip-custom-blue chip-custom-blue--text mr-2"
                        >mdi-account-clock</v-icon
                      >
                      <span v-if="row.all_day" class="py-1"
                        >{{ formatDate(row.started_at) }}
                      </span>
                      <span v-else class="py-1"
                        >{{ formatDate(row.started_at) }}
                        {{ formatTime(row.started_at) }} -
                        {{ formatTime(row.finished_at) }}
                      </span>
                    </p>
                  </div>
                </div>
              </template>
              <template v-else>
                <h3
                  class="custom-header-blue-text font-size-18 m-0 text-uppercase px-2"
                  style="line-height: 52px !important"
                >
                  No Un-Assigned Visits
                </h3>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </DetailTemplate>
    <SingleVisitDetail
      is-calendar
      :visit-dialog="eventClickedDialog"
      :visit="visitId"
      v-on:reload="reloadCalendar()"
      v-on:close="eventClickedDialog = false"
    ></SingleVisitDetail>
  </div>
  <!--end::Calendar-->
</template>

<style>
#bt-calendar.fc.fc-media-screen .fc-view-harness {
  height: calc(100vh - 220px) !important;
}
.fc-timegrid-axis-cushion,
.fc-timegrid-slot-label-cushion {
  font-weight: 600;
}
</style>

<script>
import moment from "moment-timezone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template";
import CommonMixin from "@/core/plugins/common-mixin";
import { Calendar } from "@fullcalendar/core";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
// import JwtService from "@/core/services/jwt.service";
import APIService from "@/core/services/api.service";
import { toSafeInteger, isEmpty } from "lodash";
import Barcode from "@/view/pages/partials/Barcode.vue";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import SingleVisitDetail from "@/view/pages/job/partials/Single-Visit-Detail.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "resource-calendar",
  mixins: [CommonMixin],
  components: {
    Barcode,
    Datepicker,
    DetailTemplate,
    SingleVisitDetail,
  },
  data() {
    return {
      draggableEvent: null,
      calendar: null,
      startDate: moment().startOf("day").format("YYYY-MM-DD"),
      endDate: moment().endOf("day").format("YYYY-MM-DD"),
      dragged: {},
      defaultDate: moment().startOf("day").format("YYYY-MM-DD"),
      statusLoading: false,
      statusList: [],
      status: [],
      customerLoading: false,
      customerList: [],
      customer: [],
      engineerLoading: false,
      engineerList: [],
      engineer: [],
      visit: [],
      visitId: null,
      eventClickedDialog: false,
      timeout: null,
      timeoutLimit: 500,
    };
  },
  methods: {
    setDefaultDate(param) {
      if (param) {
        this.defaultDate = param;
        if (this.calendar) {
          this.calendar.gotoDate(param);
        }
      }
    },
    reloadCalendar() {
      const _this = this;
      clearTimeout(_this.timeout);
      _this.timeout = setTimeout(function () {
        _this.removeAllEvents();
        _this.refetchEvents();
        _this.refetchResources();
        _this.getUnassignedVisits();
      }, _this.timeoutLimit);
    },
    refetchEvents() {
      this.calendar.refetchEvents();
    },
    refetchResources() {
      this.calendar.refetchResources();
    },
    removeAllEvents() {
      this.calendar.removeAllEvents();
    },
    initCalendar() {
      const calendarEl = document.getElementById("bt-calendar");

      this.calendar = new Calendar(calendarEl, {
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        plugins: [resourceTimeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev today next",
          center: "title",
          right:
            "resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth",
        },
        aspectRatio: 2.2,
        initialView: "resourceTimeGridDay",
        views: {
          resourceTimeGridThreeDay: {
            type: "resourceTimeGrid",
            duration: { days: 3 },
            buttonText: "3 days",
          },
        },
        editable: true,
        selectable: true,
        droppable: true,
        nowIndicator: true,
        eventResize: this.eventResized,
        eventDrop: this.eventDropped,
        eventClick: this.eventClicked,
        datesSet: this.dateChanged,
        drop: this.dropped,
        resources: this.getResources,
        events: this.getEvents,
      });

      this.calendar.render();
    },
    eventResized(param) {
      try {
        const event = param.event;

        const extended_props = event.extendedProps;

        const params = {
          visit: extended_props.visitId,
          started_at: event.startStr.substr(0, 19),
          finished_at: event.endStr.substr(0, 19),
        };

        APIService.patch("resource-calendar/event-resized", params)
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.refetchEvents();
          });
      } catch (error) {
        this.logError(error);
        param.revert();
      }
    },
    eventDropped(param) {
      try {
        const event = param.event;

        const new_resource = param.newResource;

        const old_resource = param.oldResource;

        const extended_props = event.extendedProps;

        const is_all_day = toSafeInteger(event.allDay);

        const params = {
          all_day: is_all_day,
          date: is_all_day ? event.startStr.substr(0, 19) : null,
          visit: extended_props.visitId,
          engineer: new_resource.id,
          del_engineer: old_resource.id,
          started_at: is_all_day ? null : event.startStr.substr(0, 19),
          finished_at: is_all_day ? null : event.endStr.substr(0, 19),
        };

        if (!event.allDay && !event.end) {
          params.finished_at = moment(event.startStr)
            .add(1, "hour")
            .format("YYYY-MM-DDTHH:mm:ss");
        }

        APIService.patch("resource-calendar/event-dropped", params)
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.refetchEvents();
          });
      } catch (error) {
        this.logError(error);
        param.revert();
      }
    },
    eventClicked(param) {
      const event = param.event;
      if (event) {
        const extended_props = event.extendedProps;
        this.visitId = extended_props.visitId;
        this.eventClickedDialog = true;
      }
    },
    getResources(fetchInfo, successCallback, failureCallback) {
      APIService.query("resource-calendar/resources", {
        engineers: this.engineer,
      })
        .then(({ data }) => {
          successCallback(data);
        })
        .catch((error) => {
          failureCallback(error);
        });
    },
    getEvents(fetchInfo, successCallback, failureCallback) {
      const params = {
        start: moment(fetchInfo.start).format("YYYY-MM-DDTHH:mm"),
        end: moment(fetchInfo.end).format("YYYY-MM-DDTHH:mm"),
        engineers: this.engineer,
        customers: this.customer,
        statuses: this.status,
      };

      APIService.query("resource-calendar/events", params)
        .then(({ data }) => {
          successCallback(data);
        })
        .catch((error) => {
          failureCallback(error);
        });
    },
    initExternalDraggable() {
      const _this = this;

      const containerEl = document.getElementById("external-draggable");

      if (_this.draggableEvent) {
        _this.draggableEvent.destroy();
      }

      _this.$nextTick(() => {
        _this.draggableEvent = new Draggable(containerEl, {
          itemSelector: ".fc-event",
          eventData: function (eventEl) {
            const attribute = eventEl.dataset;
            if (attribute && attribute.event) {
              _this.dragged = JSON.parse(attribute.event);
            }
            return {
              title: eventEl.innerText,
            };
          },
        });
      });
    },
    dateChanged({ startStr, endStr }) {
      this.startDate = startStr.substr(0, 19);
      this.endDate = endStr.substr(0, 19);
      this.getUnassignedVisits();
    },
    getUnassignedVisits() {
      const dates = {
        start: moment(this.startDate).format("YYYY-MM-DD"),
        end: moment(this.endDate).format("YYYY-MM-DD"),
        customers: this.customer,
      };

      APIService.query("resource-calendar/un-assigned-visits", dates)
        .then(({ data }) => {
          this.visit = data.data;
          if (this.visit.length) {
            setTimeout(this.initExternalDraggable, 200);
          }
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    dropped(param) {
      try {
        const event = this.dragged;

        if (isEmpty(event)) {
          this.getUnassignedVisits();
          this.removeAllEvents();
          this.$nextTick(() => {
            this.refetchEvents();
          });
          return false;
        }

        const is_all_day = toSafeInteger(event.all_day);
        const { resource } = param;

        const params = {
          all_day: is_all_day,
          date: is_all_day ? event.started_at : null,
          visit: event.id,
          engineer: resource.id,
          del_engineer: 0,
          started_at: is_all_day ? null : event.started_at,
          finished_at: is_all_day ? null : event.finished_at,
        };

        APIService.patch("resource-calendar/event-dropped", params)
          .then(() => {
            this.dragged = {};
            this.getUnassignedVisits();
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.removeAllEvents();
            this.$nextTick(() => {
              this.refetchEvents();
            });
          });
      } catch (error) {
        this.logError(error);
      }
    },
    initFilter() {
      APIService.query("resource-calendar/filters")
        .then(({ data }) => {
          this.customerList = data.customers;
          this.engineerList = data.engineers;
          this.statusList = data.statuses;
        })
        .catch((error) => {
          this.logError(error);
        });
    },
  },
  mounted() {
    this.initCalendar();
    this.initFilter();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Resource Calendar" }]);
  },
};
</script>
